<script setup>
import challengeCard from '../components/challengeCard.vue'
import SKchallengeCard from '../components/skeletons/SKchallengeCard.vue'
import {
    ref,
    toRefs,
    getCurrentInstance,
    computed,
    onMounted
} from 'vue'
import {
    useRoute,
    useRouter
} from 'vue-router'
import {
    DriverStore
} from '../pinia/Driver.js'

const props = defineProps({
    data: Object,
})
const {
    data
} = toRefs(props)
const Driver = DriverStore()
const {
    proxy
} = getCurrentInstance()
const route = useRoute()
const router = useRouter()
const items = ref([])
const date_title = ref("")
const done = ref([])
const dialog = ref(false)
const playerDone = ref([])
const isPink = ref(false)
const playerDoneCount = ref([])
const dialogCreate = ref(false) // Dialogue pour créer un challenge
const newChallenge = ref({
    year: '', 
    week: '', 
    track: '', 
    image_by: '', 
    image_url: '', 
    copsRammed: '', 
    Operator_copsRammed: '', 
    copsDisabled: '', 
    Operator_copsDisabled: '', 
    topSpeed: '', 
    costToState: '', 
    Operator_costToState: '', 
    eventDurationInMilliseconds_min: '', 
    eventDurationInMilliseconds_sec: '', 
    Operator_eventDurationInMilliseconds: '', 
    powerups: false, 
    class: '', 
    car: '', 
    reward: '', 
    reward_type: ''
})
const trackList = ref([]) // Liste des pistes
const carsList = ref([]) // Liste des voitures

// Fonction pour obtenir la date actuelle dans la timezone CEST
function getCurrentDateCEST() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const cestOffset = -120; // CEST est UTC+2
    date.setMinutes(date.getMinutes() - offset + cestOffset);
    return date;
}

// Utiliser cette fonction pour obtenir la date actuelle
var currentDate = getCurrentDateCEST();

// Modification de la fonction de calcul de la semaine
function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
}

// Utiliser cette nouvelle fonction pour obtenir le numéro de semaine
var weekNumber = getWeekNumber(currentDate);

const zeroPad = (num, places) => String(num).padStart(places, '0')

getDone()

function pink() {
    isPink.value = true
    document.querySelector('.v-app-bar').style.display = "none"
    document.querySelector('.grid').style.background = "rgb(255, 0, 255)"
    document.querySelector('.grid').style.borderRadius = "6px";

    document.querySelectorAll('.challenge_card').forEach(e => {
        e.style.background = "white"
        e.style.color = "black"
        e.style.boxShadow = "none"
    })

    document.querySelectorAll('.custom').forEach(e => {
        e.style.color = "white"
        e.style.boxShadow = "none"
    })
}

// Fonction pour récupérer la liste des pistes
async function getTrackList() {
    const res = await proxy.$api({
        serviceName: "challengesmanager",
        methodName: "GetTrackList",
        parameters: [newChallenge.value.week]
    })
    trackList.value = res
}

// Fonction pour récupérer la liste des voitures
async function getCarsList() {
    const res = await proxy.$api({
        serviceName: "challengesmanager",
        methodName: "GetCarsList",
        parameters: []
    })
    carsList.value = res
}

// Charger les données au montage du composant
onMounted(() => {
    getTrackList() // Récupérer la liste des pistes
    getCarsList() // Récupérer la liste des voitures
})

// Fonction pour récupérer les challenges d'une semaine spécifique
async function getChallenges(year = route.params.year, week = route.params.week) {
    !year ? year = currentDate.getFullYear() : ''
    !week ? week = weekNumber : ''

    router.push('/challenges/' + year + '/' + zeroPad(week, 2))

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetWeek",
        parameters: [year, week]
    })
    date_title.value = res.date
    items.value = res.loop
}

// Fonction pour récupérer les challenges déjà réalisés par le joueur
async function getDone(year = route.params.year, week = route.params.week) {
    !year ? year = currentDate.getFullYear() : '';
    !week ? week = weekNumber : '';

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetDone",
        parameters: [year, week]
    });

    done.value = res;
    getChallenges(year, week);
}

// Fonction pour créer un nouveau challenge
async function createChallenge() {
    const duration = +newChallenge.value.eventDurationInMilliseconds_min * (60 * 1000) +
        1000 * +newChallenge.value.eventDurationInMilliseconds_sec

    const res = await proxy.$api({
        serviceName: "challengesmanager",
        methodName: "CreateChallenge",
        parameters: [
            newChallenge.value.year, 
            newChallenge.value.week, 
            newChallenge.value.reward_type, 
            newChallenge.value.track, 
            newChallenge.value.image_by, 
            newChallenge.value.image_url, 
            newChallenge.value.copsRammed, 
            newChallenge.value.Operator_copsRammed, 
            newChallenge.value.copsDisabled, 
            newChallenge.value.Operator_copsDisabled, 
            newChallenge.value.costToState, 
            newChallenge.value.Operator_costToState, 
            duration, 
            newChallenge.value.Operator_eventDurationInMilliseconds, 
            newChallenge.value.powerups, 
            newChallenge.value.class, 
            newChallenge.value.car, 
            newChallenge.value.reward, 
            newChallenge.value.reward_type, 
            newChallenge.value.topSpeed
        ]
    })

    if (res.status === "ok") {
        dialogCreate.value = false;
        getChallenges(); // Actualiser la liste des challenges
    } else {
        alert("Erreur lors de la création du challenge.");
    }
}

// Fonction pour revendiquer un challenge
async function claim(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetClaim",
        parameters: [id]
    })
    getDone()
}

// Fonction pour afficher les joueurs ayant complété un challenge
async function PlayerDone(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetPlayerDoneMoonset",
        parameters: [id]
    });
    playerDone.value = res.names;
    playerDoneCount.value = res.count
    dialog.value = true;
}

// Fonction pour changer la semaine sélectionnée
function dateChange(e) {
    items.value = [];
    var value = e.target.value.split("-");
    var y = value[0];
    var w = value[1].substring(1);
    getDone(y, w);
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-icon icon="mdi-trophy-award"></v-icon>
    <v-app-bar-title>Challenges</v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-if="Driver.user.isAdmin == '1'">
        <v-btn variant="tonal" prepend-icon="mdi-plus-box" @click="dialogCreate = true">
            Créer un Challenge
        </v-btn>
        <v-btn variant="tonal" prepend-icon="mdi-link-variant" @click="pink">
            Screenshot mode
        </v-btn>
    </template>
    <template v-slot:extension>
        <div>
        <label>Selected week: </label>
        <input type="week" id="week" name="week" :value="route.params.year + '-W' + route.params.week" :max="currentDate.getFullYear() + '-W0' + weekNumber" style="color:white" @change="dateChange">
        </div>
    </template>
</v-app-bar>

<!-- Formulaire pour créer un nouveau challenge -->
<v-dialog v-model="dialogCreate" max-width="500px">
    <v-card>
        <v-card-title>Créer un Challenge</v-card-title>
        <v-card-text>
            <v-select label="Track" v-model="newChallenge.track" :items="trackList" item-text="name" item-value="id"></v-select>
            <v-text-field label="Image par" v-model="newChallenge.image_by"></v-text-field>
            <v-text-field label="Cops Rammed" v-model="newChallenge.copsRammed"></v-text-field>
            <v-select label="Opérateur Cops Rammed" v-model="newChallenge.Operator_copsRammed" :items="['+', '-', '=']"></v-select>
            <v-text-field label="Vitesse maximale (Km/h)" v-model="newChallenge.topSpeed"></v-text-field>
            <v-text-field label="Cost to State" v-model="newChallenge.costToState"></v-text-field>
            <v-select label="Voiture" v-model="newChallenge.car" :items="carsList" item-text="model" item-value="store_name"></v-select>
            <v-text-field label="Récompense" v-model="newChallenge.reward"></v-text-field>
            <v-select label="Type de récompense" v-model="newChallenge.reward_type" :items="['Cash', 'SpeedBoost']"></v-select>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" @click="createChallenge">Créer</v-btn>
            <v-btn @click="dialogCreate = false">Annuler</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

<transition name="fade" mode="out-in">
    <div key=1 class="grid skeleton" v-if="items.length == 0">
        <SKchallengeCard v-for="i in 8"/>
    </div>
    <div key=2 class="grid" v-else>
        <challengeCard v-for="(item, index) in items" :key="item.ID" :data="item" :pink="isPink" :done="done[index]" @claim="claim" @PlayerDone="PlayerDone" />
    </div>
</transition>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1350px;
    margin: 0 auto;
}
</style>
